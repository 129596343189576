import axios from "../../axiosSegaraCo2";
import fuels from "../../data/fuels.json";
import vehicles from "../../data/vehicles.json";
import store from "@/store";

const state = {
  invoices: [],
  model: [
    {
      name: "invoice_number",
      value: "",
      icon: "mdi-file-document",
      form: true,
      headers: {
        text: "Número",
        align: "start",
        sortable: true,
        value: "invoice_number",
        groupable: false,
      },
    },
    {
      name: "invoice_date",
      value: "",
      icon: "mdi-calendar",
      date: true,
      form: true,
      headers: {
        text: "Fecha",
        value: "invoice_date",
      },
    },
    {
      name: "fuel_name",
      value: "",
      icon: "mdi-gas-station",
      options: fuels.map((item) => {
        return item["name"];
      }),
      //options: ["E5", "E10", "B7"], // use getter instead
      //options: rootGetters.mapFuels,
      form: true,
      headers: {
        text: "Combustible",
        value: "fuel", // fuel.name does not work
      },
    },
    {
      name: "units",
      value: "",
      icon: "mdi-water",
      form: true,
      headers: {
        text: "Unidades",
        value: "units",
      },
    },
    {
      name: "total",
      value: "",
      icon: "mdi-cash",
      form: true,
      headers: {
        text: "Total",
        value: "total",
      },
    },
    {
      name: "vehicle_license_plate",
      value: "",
      icon: "mdi-car",
      form: true,
      //Return only ICV vehicles
      options: vehicles
        .filter((item) => {
          if (item.emissionFactor > 0) {
            return item;
          }
        })
        .map((item) => {
          return item["name"];
        }),
      headers: {
        text: "Vehículo",
        value: "vehicle_license_plate",
      },
    },
    {
      name: "partialCo2",
      form: false,
      headers: {
        text: "Parcial CO2",
        value: "partial_co2",
      },
    },
    {
      name: "notes",
      form: false,
      headers: {
        text: "Notas",
        value: "notes",
      },
    },
  ],
};

const mutations = {
  massageData: (state, api_invoices) => {
    api_invoices.forEach((invoice) => {
      let fuel = invoice["fuel"];
      invoice["fuel"] = fuel["name"];
    });
    state.invoices = api_invoices;
  },
};

const actions = {
  readInvoices: ({ commit }, payload) => {
    axios
      .get(
        `mobile_combustion_invoices/search/dates/?date1=${payload}-01-01&date2=${payload}-12-31`
      )
      .then((response) => {
        commit("massageData", response.data);
      });
  },

  createInvoice: ({ dispatch }, payload) => {
    axios
      .post("mobile_combustion_invoices/create/", payload.data)
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readInvoices", payload.year));
  },

  importInvoices: ({ dispatch }, payload) => {
    axios
      .post("mobile_combustion_invoices/import/all/", payload.data)
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readInvoices", payload.year));
  },

  updateInvoice: ({ dispatch }, payload) => {
    axios
      .put("mobile_combustion_invoices/edit/id/", payload.data.editedItem, {
        params: {
          invoice_id: payload.data.id,
        },
      })
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readInvoices", payload.year));
  },

  deleteInvoices: ({ dispatch }, payload) => {
    payload.data.forEach((invoice) => {
      axios
        .delete("mobile_combustion_invoices/delete/id/", {
          params: {
            invoice_id: invoice.id,
          },
        })
        .then(() => (store.state.selected = []))
        // We want to update the data table after removing data from the database
        .then(() => dispatch("readInvoices", payload.year));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
