import Vue from "vue";
import VueRouter from "vue-router";

import MobileCombustion from "../components/inventory/combustion/MobileCombustion.vue";
import StationaryCombustionInvoice from "../components/inventory/combustion/StationaryCombustionInvoice.vue";
import Fluorocarbon from "../components/inventory/combustion/Fluorocarbon.vue";
import MobileElectricityInvoice from "../components/inventory/electricity/MobileElectricityInvoice.vue";
import StationaryElectricityInvoice from "../components/inventory/electricity/StationaryElectricityInvoice.vue";
import Report from "../components/Report.vue";
import AdminVehicles from "../components/administration/AdminVehicles.vue";
import AdminFacilities from "../components/administration/AdminFacilities.vue";
import AdminEquipments from "../components/administration/AdminEquipments.vue";
import AdminOrganisation from "../components/administration/AdminOrganisation.vue";
import Login from "../components/Login.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  { path: "", component: MobileCombustion, meta: { requiresAuth: true } },
  {
    path: "/mobile-combustion",
    component: MobileCombustion,
    meta: { requiresAuth: true },
  },
  {
    path: "/stationary-combustion",
    component: StationaryCombustionInvoice,
    meta: { requiresAuth: true },
  },
  {
    path: "/fluorocarbon",
    component: Fluorocarbon,
    meta: { requiresAuth: true },
  },
  {
    path: "/mobile-electricity",
    component: MobileElectricityInvoice,
    meta: { requiresAuth: true },
  },
  {
    path: "/stationary-electricity",
    component: StationaryElectricityInvoice,
    meta: { requiresAuth: true },
  },
  { path: "/reports", component: Report, meta: { requiresAuth: true } },
  { path: "/vehicles", component: AdminVehicles, meta: { requiresAuth: true } },
  {
    path: "/facilities",
    component: AdminFacilities,
    meta: { requiresAuth: true },
  },
  {
    path: "/equipments",
    component: AdminEquipments,
    meta: { requiresAuth: true },
  },
  {
    path: "/organisation",
    component: AdminOrganisation,
    meta: { requiresAuth: true },
  },
  { path: "/login", name: "Login", component: Login, meta: { guest: true } },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !store.getters["auth/isAuthenticated"])
    next({ name: "Login" });
  else next();
});

export default router;
