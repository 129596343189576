<template>
  <v-list>
    <v-list-item>
      <v-list-item-avatar color="primary">
        {{ this.initials }}
      </v-list-item-avatar>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{ this.user.fullname }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ this.user.email }}</v-list-item-subtitle>
      </v-list-item-content>

      <!-- <v-list-item-action>
        <v-icon>mdi-menu-down</v-icon>
      </v-list-item-action> -->
    </v-list-item>
  </v-list>
</template>

<script>
import { mapActions } from "vuex";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("users", ["user"]),
    initials() {
      var parts = this.user.fullname.split(" ");
      var initials = "";
      for (var i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      return initials;
    },
  },

  methods: {
    ...mapActions({
      readCurrentUser: "users/readCurrentUser",
    }),
  },

  created() {
    this.$store.dispatch("users/readCurrentUser");
  },
};
</script>
