import axios from "../../axiosSegaraCo2";
//import store from "@/store";

const state = {
  organisations: [],
};

const mutations = {
  saveOrganisations: (state, payload) => {
    state.organisations = payload;
  },
};

const actions = {
  readOrganisations: ({ commit }) => {
    axios
      .get("/organisations/search/all/?skip=0&limit=100")
      .then((response) => {
        commit("saveOrganisations", response.data);
      });
  },

  updateOrganisation: ({ dispatch }, payload) => {
    axios
      .put("/organisations/update/id/", payload.editedItem, {
        params: {
          organisation_id: payload.id,
        },
      })
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readOrganisations"));
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
