import axios from "../../axiosSegaraCo2";

const state = {
  drivings: [],
};

const mutations = {
  saveDrivings: (state, payload) => {
    state.drivings = payload;
  },
};

const actions = {
  readDrivings: ({ commit }) => {
    axios.get("drivings/search/all/?skip=0&limit=100").then((response) => {
      commit("saveDrivings", response.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
