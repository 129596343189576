<template>
  <v-container>
    <v-row>
      <v-col
        ><v-card class="mx-auto my-12" max-width="800">
          <v-card-title>
            {{ title }}
          </v-card-title>
          <v-card-text>
            <!-- The ref newForm is needed to reset the form -->
            <v-form ref="newForm">
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    :items="years"
                    label="Año"
                    v-model="new_report.year"
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    :items="similes"
                    label="Símiles"
                    v-model="new_report.simile_name"
                  ></v-select>
                </v-col>
                <!-- <v-col cols="12" sm="6">
                  <v-select
                    :items="facilities"
                    chips
                    label="Instalaciones"
                    multiple
                  ></v-select>
                </v-col> -->
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex justify-center pb-4 pr-4 mt-0 pt-0">
            <v-btn x-large color="primary" @click="createReport()">
              <v-icon left>mdi-file-pdf-box</v-icon>Generar Informe
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "../axiosSegaraCo2";
import { mapState } from "vuex";

export default {
  data() {
    return {
      title: "Nuevo informe",
      similes: [
        "Estadio Santiago Bernabéu",
        "Museo Guggenheim",
        "Alcázar de Toledo",
      ],
      new_report: {
        organisation_name: "",
        year: "",
        simile_name: "",
      },
    };
  },

  computed: {
    ...mapState("organisations", ["organisations"]),
    years() {
      return Array.from(new Array(16), (x, i) => i + 2015).map(String);
    },
  },

  methods: {
    createReport() {
      console.log(this.new_report.year);
      axios
        .post("report/", this.new_report, { responseType: "blob" })
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "Report.pdf"); //any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    },
  },

  created() {
    this.$store.dispatch("organisations/readOrganisations");
    this.new_report["organisation_name"] = this.organisations[0].name;
  },
};
</script>
