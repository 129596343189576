import axios from "../../axiosSegaraCo2";
import router from "../../router";

const state = {
  user: null,
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  stateUser: (state) => state.user,
};

const actions = {
  async login({ commit }, User) {
    // axios.post("login/token", User).then(() => {
    //   commit("setUser", User.get("username")).then((response) => {
    //     localStorage.setItem("jwt", response.data.token);
    //   });
    // });
    const response = await axios.post("login/token", User);
    await commit("setUser", User.get("username"));
    localStorage.setItem("jwt", response.data.access_token);
  },
  async logout({ commit }) {
    let user = null;
    commit("logout", user);
    localStorage.clear();
    router.push("/login");
  },
};

const mutations = {
  setUser(state, username) {
    state.user = username;
  },
  logout(state) {
    state.user = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
