import axios from "../../axiosSegaraCo2";
import store from "@/store";

const state = {
  vehicles: [],
  model: [
    {
      name: "license_plate",
      value: "",
      icon: "mdi-numeric",
      form: true,
      headers: {
        text: "Matrícula",
        align: "start",
        sortable: true,
        value: "license_plate",
        groupable: false,
      },
    },
    {
      name: "make",
      value: "",
      icon: "mdi-factory",
      form: true,
      headers: {
        text: "Fabricante",
        value: "make",
      },
    },
    {
      name: "commercial_name",
      value: "",
      icon: "mdi-car",
      form: true,
      headers: {
        text: "Nombre comercial",
        value: "commercial_name",
      },
    },
    {
      name: "version",
      value: "",
      icon: "mdi-engine",
      form: true,
      headers: {
        text: "Versión",
        value: "version",
      },
    },
    {
      name: "car_year",
      value: "",
      icon: "mdi-calendar",
      form: true,
      headers: {
        text: "Año",
        value: "car_year",
      },
    },
    {
      name: "fleet_name",
      value: "",
      icon: "mdi-office-building",
      options: ["Flota comercial"],
      form: true,
      headers: {
        text: "Flota",
        value: "fleet_name",
      },
    },
    {
      name: "emissions_nedc",
      value: "",
      icon: "mdi-molecule-co2",
      form: true,
      headers: {
        text: "Emisiones NEDC",
        value: "emissions_nedc",
      },
    },
    {
      name: "emissions_wltp",
      value: "",
      icon: "mdi-molecule-co2",
      form: true,
      headers: {
        text: "Emisiones WLTP",
        value: "emissions_wltp",
      },
    },
    {
      name: "vehicle_type_name",
      value: "",
      icon: "mdi-tag-text",
      options: ["BEV", "HEV", "PHEV", "CNG", "ICE"],
      form: true,
      headers: {
        text: "Tipo",
        value: "vehicle_type_name",
      },
    },
    {
      name: "outliers",
      value: "",
      icon: "mdi-chart-bell-curve",
      form: true,
      headers: {
        text: "Valores atípicos",
        value: "outliers",
      },
    },
  ],
};

const mutations = {
  saveVehicles: (state, payload) => {
    state.vehicles = payload;
  },
};

const actions = {
  readVehicles: ({ commit }) => {
    axios.get("vehicles/search/all/?skip=0&limit=100").then((response) => {
      commit("saveVehicles", response.data);
    });
  },

  createVehicle: ({ dispatch }, payload) => {
    axios
      .post("vehicles/create/", payload)
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readVehicles"));
  },

  importVehicles: ({ dispatch }, payload) => {
    axios
      .post("vehicles/import/all/", payload)
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readVehicles"));
  },

  updateVehicle: ({ dispatch }, payload) => {
    axios
      .put("vehicles/edit/id/", payload.editedItem, {
        params: {
          vehicle_id: payload.id,
        },
      })
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readVehicles"));
  },

  deleteVehicles: ({ dispatch }, payload) => {
    payload.forEach((vehicle) => {
      axios
        .delete("vehicles/delete/id/", {
          params: {
            vehicle_id: vehicle.id,
          },
        })
        .then(() => (store.state.selected = []))
        // We want to update the data table after removing data from the database
        .then(() => dispatch("readVehicles"));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
