import axios from "../../axiosSegaraCo2";
//import store from "@/store";

const state = {
  sectors: [],
};

const mutations = {
  saveSectors: (state, payload) => {
    const sector_names = [];
    payload.forEach((sector) => {
      sector_names.push(sector["name"]);
    });
    state.sectors = sector_names;
  },
};

const actions = {
  readSectors: ({ commit }) => {
    axios.get("/sectors/search/all/?skip=0&limit=100").then((response) => {
      commit("saveSectors", response.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
