<template>
  <div>
    <User />
    <v-divider></v-divider>
    <v-list dense>
      <template v-for="(subheader, index1, index2) in subheaders">
        <v-subheader :key="index1">{{ subheader.title }}</v-subheader>
        <v-list-item-group v-model="selectedItem" :key="index2">
          <v-list-item
            v-for="(item, i) in subheader.items"
            :key="i"
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </template>
      <v-divider></v-divider>
      <v-list-group
        v-for="item in items"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.action"
        color="white"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item-group>
          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            :to="child.route"
          >
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
import User from "./User.vue";

export default {
  data: () => ({
    selectedItem: 0,
    subheaders: [
      {
        title: "INVENTARIOS ALCANCE 1",
        items: [
          {
            text: "Fósiles - Vehículos",
            icon: "mdi-car",
            route: "/mobile-combustion",
          },
          {
            text: "Fósiles - Instalaciones",
            icon: "mdi-office-building",
            route: "/stationary-combustion",
          },
          {
            text: "Fluorados",
            icon: "mdi-air-conditioner",
            route: "/fluorocarbon",
          },
        ],
      },
      {
        title: "INVENTARIOS ALCANCE 2",
        items: [
          {
            text: "Electricidad - Vehículos",
            icon: "mdi-car-electric",
            route: "/mobile-electricity",
          },
          {
            text: "Electricidad - Instalaciones",
            icon: "mdi-office-building",
            route: "/stationary-electricity",
          },
        ],
      },
      {
        title: "ANÁLISIS DE DATOS",
        items: [
          { text: "Informes", icon: "mdi-file-chart", route: "/reports" },
          // { text: "Exportar", icon: "mdi-export", route: "/export" },
        ],
      },
    ],
    items: [
      {
        action: "mdi-database",
        active: false,
        items: [
          { title: "Vehículos", route: "/vehicles" },
          { title: "Instalaciones", route: "/facilities" },
          { title: "Equipos", route: "/equipments" },
          { title: "Organización", route: "/organisation" },
        ],
        title: "Administrar",
      },
      // {
      //   action: "mdi-help-circle",
      //   active: false,
      //   items: [
      //     { title: "FAQ", route: "/faq" },
      //     { title: "Soporte", route: "support" },
      //     { title: "Acerca de", route: "/about" },
      //   ],
      //   title: "Ayuda",
      // },
    ],
  }),
  components: { User },
};
</script>
