<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" text v-bind="attrs" v-on="on"
        >Carga masiva<v-icon right dark>mdi-cloud-upload</v-icon></v-btn
      >
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Selecciona CSV</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-file-input
              counter
              label="File input"
              accept="text/csv"
              ref="file"
              id="file"
            ></v-file-input>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
        <v-btn color="blue darken-1" text @click="uploadFile">
          Cargar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    file: "",
    dialog: false,
    // TODO:
    // rules: [
    //   (v) =>
    //     this.$refs.file.files[0].name.split(".").pop() === "csv"
    //       ? v
    //       : "File must be a CSV.",
    // ],
  }),

  computed: {
    dialog() {
      return this.$store.state.dialog;
    },
  },

  methods: {
    uploadFile() {
      let formData = new FormData();
      this.file = document.querySelector("#file");
      formData.append("file", this.file.files[0]);
      this.dialog = false;
      this.$emit("imported", formData);
    },
  },
};
</script>
