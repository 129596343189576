<template>
  <v-app id="app">
    <v-navigation-drawer
      class="primary darken-1"
      v-if="isLoggedIn"
      dark
      v-model="drawer"
      app
    >
      <List />
      <template v-slot:append>
        <div class="pa-2">
          <v-btn @click="logout" block color="secondary" outlined>
            Salir
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar class="primary" v-if="isLoggedIn" dark app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>SegaraCO2</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn icon>
        <v-icon>mdi-help-circle</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main class="grey lighten-3">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import List from "./components/List.vue";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: "SegaraCO",
  data: () => ({ drawer: null }),
  components: {
    List,
  },

  computed: {
    ...mapGetters({ isLoggedIn: "auth/isAuthenticated" }),
  },

  methods: {
    ...mapActions({
      readFuels: "fuels/readFuels",
      readFacilities: "facilities/readFacilities",
      readVehicles: "vehicles/readVehicles",
      readDrivings: "drivings/readDrivings",
      readSuppliers: "suppliers/readSuppliers",
      readGases: "gases/readGases",
      readEquipments: "equipments/readEquipments",
      logout: "auth/logout",
    }),
  },

  created() {
    this.$store.dispatch("fuels/readFuels");
    this.$store.dispatch("facilities/readFacilities");
    this.$store.dispatch("vehicles/readVehicles");
    this.$store.dispatch("drivings/readDrivings");
    this.$store.dispatch("suppliers/readSuppliers");
    this.$store.dispatch("gases/readGases");
    this.$store.dispatch("equipments/readEquipments");
  },
};
</script>
