<template>
  <!-- Listens to the 'save-data' event and adds the new object
  to the fluorocarbons stored in Vuex -->
  <Container
    :formTitle="formTitle"
    :tableTitle="tableTitle"
    :newItem="fluorocarbon"
    :search="search"
    :headers="headers"
    :records="fluorocarbons"
    :outliers="outliers"
    @save-data="createFluorocarbon({ data: $event, year: currentYear })"
    @edited="updateFluorocarbon({ data: $event, year: currentYear })"
    @deleted="deleteFluorocarbons({ data: $event, year: currentYear })"
    @imported="importFluorocarbons({ data: $event, year: currentYear })"
    @year-selected="loadFluorocarbons($event)"
    :currentYear="currentYear"
    :itemKey="itemKey"
    :showYearSelector="true"
  />
</template>

<script>
import Container from "../common/Container.vue";
import { mapActions } from "vuex";
import { mapState } from "vuex";

export default {
  data() {
    return {
      formTitle: "Nuevo Fluorado",
      tableTitle: "Historial de Fluorados",
      search: "",
      outliers: [5000, 4000],
      itemKey: "id",
      currentYear: "2021",
    };
  },
  computed: {
    // Returns data-table data array from fluorocarbons stored in Vuex
    ...mapState("fluorocarbons", ["fluorocarbons", "model"]),

    /* Creates and returns the data-table headers array
    from fluorocarbonModel.headers stored in Vuex */
    headers() {
      return this.model.map((item) => {
        return item["headers"];
      });
    },

    /* Creates and returns a custom object
    to dynamically render the Form component fields,
    based on specific properties
    from fluorocarbonModel stored in Vuex */
    fluorocarbon() {
      const obj1 = {}; // The returned object
      /* Creates a nested object in obj1,
      for each object found in fluorocarbonModel stored in Vuex
      which form property evaluates to true.
      Example:
      {
        refilling: {
          value: "",
          text: "Recarga anual",
          icon: "mdi-water"
        },
        ...
      } */
      this.model.forEach((x) => {
        if (x.form) {
          let obj2 = {}; // The nested object
          Object.assign(obj2, { value: x.value });
          Object.assign(obj2, { text: x.headers.text });
          Object.assign(obj2, { icon: x.icon });
          if (x.options) {
            Object.assign(obj2, { options: x.options });
          }
          if (x.date) {
            Object.assign(obj2, { date: true });
          }
          Object.assign(obj1, { [x.name]: obj2 });
        }
      });
      return obj1;
    },
  },

  components: {
    Container,
  },

  methods: {
    ...mapActions({
      readFluorocarbons: "fluorocarbons/readFluorocarbons",
      createFluorocarbon: "fluorocarbons/createFluorocarbon",
      updateFluorocarbon: "fluorocarbons/updateFluorocarbon",
      deleteFluorocarbons: "fluorocarbons/deleteFluorocarbons",
      importFluorocarbons: "fluorocarbons/importFluorocarbons",
    }),

    loadFluorocarbons(year) {
      if (year != this.currentYear) {
        this.currentYear = year;
      }
      this.$store.dispatch("fluorocarbons/readFluorocarbons", year);
    },
  },

  created() {
    this.loadFluorocarbons(this.currentYear);
  },
};
</script>
