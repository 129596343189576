import axios from "../../axiosSegaraCo2";
import store from "@/store";

const state = {
  equipments: [],
  model: [
    {
      name: "nickname",
      value: "",
      icon: "mdi-air-conditioner",
      form: true,
      headers: {
        text: "Nombre corto",
        align: "start",
        sortable: true,
        value: "nickname",
        groupable: false,
      },
    },
    {
      name: "facility_name",
      value: "",
      icon: "mdi-office-building",
      form: true,
      options: ["Castellana", "Polígono"],
      headers: {
        text: "Instalaciones",
        value: "facility_name",
      },
    },
    {
      name: "equipment_type_name",
      value: "",
      icon: "mdi-tag-text",
      form: true,
      headers: {
        text: "Tipo",
        value: "equipment_type_name",
      },
    },
    {
      name: "outliers",
      value: "",
      icon: "mdi-chart-bell-curve",
      form: true,
      headers: {
        text: "Valores atípicos",
        value: "outliers",
      },
    },
  ],
};

const mutations = {
  saveEquipments: (state, payload) => {
    state.equipments = payload;
  },
};

const actions = {
  readEquipments: ({ commit }) => {
    axios.get("equipments/search/all/?skip=0&limit=100").then((response) => {
      commit("saveEquipments", response.data);
    });
  },

  createEquipment: ({ dispatch }, payload) => {
    axios
      .post("equipments/create/", payload)
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readEquipments"));
  },

  importEquipments: ({ dispatch }, payload) => {
    axios
      .post("equipments/import/all/", payload)
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readEquipments"));
  },

  updateEquipment: ({ dispatch }, payload) => {
    axios
      .put("equipments/edit/id/", payload.editedItem, {
        params: {
          equipment_id: payload.id,
        },
      })
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readEquipments"));
  },

  deleteEquipments: ({ dispatch }, payload) => {
    payload.forEach((equipment) => {
      axios
        .delete("equipments/delete/id/", {
          params: {
            equipment_id: equipment.id,
          },
        })
        .then(() => (store.state.selected = []))
        // We want to update the data table after removing data from the database
        .then(() => dispatch("readEquipments"));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
