/* Provide all the logic to calculate the partial
CO2 emissions of each record (invoice/route/fluorocarbon) */
export const partialMixin = {
  methods: {
    calculatePartial(event, object) {
      /* If the record is a combustion invoice, then
      partial = fuelEmissionFactor * units */
      if (event.units && event.fuel) {
        let partial = this.calculatePartialCombustionInvoice(
          event.fuel.value,
          event.units.value
        );
        return Object.assign(object, {
          partialCo2: partial,
        });
      }
      /* If the record is an electricity invoice, then
      partial = supplierEmissionFactor * units */
      if (event.supplier) {
        let partial = this.calculatePartialElectricityInvoice(
          event.supplier.value,
          event.units.value
        );
        return Object.assign(object, {
          partialCo2: partial,
        });
      }
      /* If the record is an fluorocarbon, then
      partial = gasEmissionFactor * refilling */
      if (event.refilling) {
        let partial = this.calculatePartialFluorocarbon(
          event.gas.value,
          event.refilling.value
        );
        return Object.assign(object, {
          partialCo2: partial,
        });
      } else {
        /* If the record is a route, then
      partial = vehicleEmissionFactor * distance */
        let partial = this.calculatePartialRoute(
          event.vehicle.value,
          event.distance.value
        );
        return Object.assign(object, {
          partialCo2: partial,
        });
      }
    },
    calculatePartialCombustionInvoice(fuel, units) {
      return units * this.findEmissionFactor(this.$store.state.fuels, fuel);
    },
    calculatePartialElectricityInvoice(supplier, units) {
      return (
        units * this.findEmissionFactor(this.$store.state.suppliers, supplier)
      );
    },
    calculatePartialRoute(vehicle, distance) {
      return (
        (distance *
          this.findEmissionFactor(this.$store.state.vehicles, vehicle)) /
        1000 // The car emission factor is in g, so we convert the result to kg
      );
    },
    calculatePartialFluorocarbon(gas, refilling) {
      return refilling * this.findEmissionFactor(this.$store.state.gas, gas);
    },
    /* Look into the fuel/car/gas array stored in Vuex,
    find the object with the same name
    than the fuel/car/gas provided by the user,
    and return its emissionFactor value */
    findEmissionFactor(objects, inventory) {
      const obj = objects.find((object) => object.name === inventory);
      return obj.emissionFactor;
    },
  },
};
