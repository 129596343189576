import axios from "../../axiosSegaraCo2";

const state = {
  fuels: [],
};

const mutations = {
  saveFuels: (state, payload) => {
    state.fuels = payload;
  },
};

const actions = {
  readFuels: ({ commit }) => {
    axios.get("fuels/search/all/?skip=0&limit=100").then((response) => {
      commit("saveFuels", response.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
