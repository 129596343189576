<template>
  <!-- Listens to the 'save-data' event and adds the new object
  to the invoices stored in Vuex -->
  <Container
    :formTitle="formTitle"
    :tableTitle="tableTitle"
    :newItem="invoice"
    :search="search"
    :headers="headers"
    :records="invoices"
    :outliers="outliers"
    @save-data="createInvoice({ data: $event, year: currentYear })"
    @edited="updateInvoice({ data: $event, year: currentYear })"
    @deleted="deleteInvoices({ data: $event, year: currentYear })"
    @imported="importInvoices({ data: $event, year: currentYear })"
    @year-selected="loadInvoices($event)"
    :currentYear="currentYear"
    :itemKey="itemKey"
    :showYearSelector="true"
  />
</template>

<script>
import Container from "../common/Container.vue";
import { mapActions } from "vuex";
import { mapState } from "vuex";

export default {
  data() {
    return {
      formTitle: "Nueva Factura",
      tableTitle: "Historial de Facturas",
      search: "",
      outliers: [2500, 2100],
      itemKey: "id",
      currentYear: "2021",
    };
  },
  computed: {
    // Returns data-table data array from invoices stored in Vuex
    ...mapState("stationaryCombustionInvoices", ["invoices", "model"]),

    /* Creates and returns the data-table headers array
    from stationaryCombustionInvoiceModel.headers stored in Vuex */
    headers() {
      return this.model.map((item) => {
        return item["headers"];
      });
    },

    /* Creates and returns a custom object
    to dynamically render the Form component fields,
    based on specific properties
    from stationaryCombustionInvoiceModel stored in Vuex */
    invoice() {
      const obj1 = {}; // The returned object
      /* Creates a nested object in obj1,
      for each object found in stationaryCombustionInvoiceModel stored in Vuex
      which form property evaluates to true.
      Example:
      {
        invoiceNumber: {
          value: "",
          text: "Número",
          icon: "mdi-file-document"
        },
        ...
      } */
      this.model.forEach((x) => {
        if (x.form) {
          let obj2 = {}; // The nested object
          Object.assign(obj2, { value: x.value });
          Object.assign(obj2, { text: x.headers.text });
          Object.assign(obj2, { icon: x.icon });
          if (x.options) {
            Object.assign(obj2, { options: x.options });
          }
          if (x.date) {
            Object.assign(obj2, { date: true });
          }
          //
          Object.assign(obj1, { [x.name]: obj2 });
        }
      });
      return obj1;
    },
  },

  components: {
    Container,
  },

  methods: {
    ...mapActions({
      readInvoices: "stationaryCombustionInvoices/readInvoices",
      createInvoice: "stationaryCombustionInvoices/createInvoice",
      updateInvoice: "stationaryCombustionInvoices/updateInvoice",
      deleteInvoices: "stationaryCombustionInvoices/deleteInvoices",
      importInvoices: "stationaryCombustionInvoices/importInvoices",
    }),

    loadInvoices(year) {
      if (year != this.currentYear) {
        this.currentYear = year;
      }
      this.$store.dispatch("stationaryCombustionInvoices/readInvoices", year);
    },
  },

  created() {
    this.loadInvoices(this.currentYear);
  },
};
</script>
