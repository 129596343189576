import axios from "../../axiosSegaraCo2";

const state = {
  user: [],
};

const mutations = {
  saveUser: (state, payload) => {
    state.user = payload;
  },
};

const actions = {
  readCurrentUser: ({ commit }) => {
    axios.get("/users/me").then((response) => {
      commit("saveUser", response.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
