import axios from "../../axiosSegaraCo2";

const state = {
  gases: [],
};

const mutations = {
  saveGases: (state, payload) => {
    state.gases = payload;
  },
};

const actions = {
  readGases: ({ commit }) => {
    axios.get("gases/search/all/?skip=0&limit=100").then((response) => {
      commit("saveGases", response.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
