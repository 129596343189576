import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import mobileCombustionInvoices from "./modules/mobileCombustionInvoices";
import stationaryCombustionInvoices from "./modules/stationaryCombustionInvoices";
import combustionRoutes from "./modules/combustionRoutes";
import fluorocarbons from "./modules/fluorocarbons";
import mobileElectricityInvoices from "./modules/mobileElectricityInvoices";
import stationaryElectricityInvoices from "./modules/stationaryElectricityInvoices";
import fuels from "./modules/fuels";
import facilities from "./modules/facilities";
import vehicles from "./modules/vehicles";
import drivings from "./modules/drivings";
import suppliers from "./modules/suppliers";
import gases from "./modules/gases";
import equipments from "./modules/equipments";
import auth from "./modules/auth";
import users from "./modules/users";
import organisations from "./modules/organisations";
import sectors from "./modules/sectors";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    selected: [],
    offices: [
      "Castellana",
      "Manoteras",
      "Nuevos Ministerios",
      "Vallecas",
      "Villaverde",
    ],
    driving: ["City", "Road", "Mixed"],
  },

  mutations: {
    updateSelected(state, selected) {
      state.selected = selected;
    },
  },

  modules: {
    mobileCombustionInvoices,
    stationaryCombustionInvoices,
    combustionRoutes,
    fluorocarbons,
    mobileElectricityInvoices,
    stationaryElectricityInvoices,
    fuels,
    facilities,
    vehicles,
    drivings,
    suppliers,
    gases,
    equipments,
    auth,
    users,
    organisations,
    sectors,
  },

  plugins: [createPersistedState()],
});

export default store;
