<template>
  <v-container>
    <v-row>
      <v-col>
        <Form
          :title="formTitle"
          :newItem="newItem"
          @update="$emit('save-data', addItem($event))"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            {{ tableTitle }}
            <v-spacer></v-spacer>
            <SearchBar :search="search" @update="search = $event" />
            <YearSelector
              v-if="showYearSelector"
              :currentYear="currentYear"
              @year-selected="$emit('year-selected', $event)"
            />
          </v-card-title>
          <v-card-text>
            <Table
              :headers="headers"
              :records="records"
              :search="search"
              :outliers="outliers"
              :showDeleteBtn="showDeleteBtn"
              @itemsSelected="showDeleteBtn = $event"
              @edited="$emit('edited', $event)"
              @selected="itemsToDelete = $event"
              :itemKey="itemKey"
            />
          </v-card-text>
          <v-card-actions>
            <UploadBtn @imported="$emit('imported', $event)" />
            <!-- <ExportBtn />
            <AdvancedExportBtn /> -->
            <!-- Delete button is displayed while at least 1 row is selected -->
            <v-fab-transition>
              <DeleteBtn
                v-if="showDeleteBtn"
                :itemsToDelete="itemsToDelete"
                @deleted="$emit('deleted', $event)"
              />
            </v-fab-transition>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Form from "./Form.vue";
import Table from "./Table.vue";
import SearchBar from "./SearchBar.vue";
import YearSelector from "./YearSelector.vue";
import UploadBtn from "./UploadBtn.vue";
// import ExportBtn from "./ExportBtn.vue";
// import AdvancedExportBtn from "./AdvancedExportBtn.vue";
import DeleteBtn from "./DeleteBtn.vue";
import { partialMixin } from "../../../partialMixin.js";

export default {
  data() {
    return {
      showDeleteBtn: false,
      itemsToDelete: [],
    };
  },
  mixins: [partialMixin],
  props: {
    formTitle: String,
    tableTitle: String,
    newItem: Object,
    search: String,
    headers: Array,
    records: Array,
    outliers: Array,
    itemKey: String,
    currentYear: String,
    showYearSelector: Boolean,
  },
  methods: {
    /* The 'save-data' custom event creates a new object 
    for the data-table data array and calculates its partialCo2,
    based on the data provided in the form by the user
    (through the 'update' event) */
    addItem(event) {
      var object = {};
      Object.keys(event).forEach((key) => {
        if (event[key].value) {
          if (key.includes("outliers")) {
            event[key].value = JSON.parse("[" + event[key].value + "]");
          }
          Object.assign(object, { [key]: event[key].value });
        }
      });
      // this.calculatePartial(event, object);
      // Object.assign(object, { notes: "" });
      return object;
    },
  },
  components: {
    Form,
    Table,
    SearchBar,
    YearSelector,
    UploadBtn,
    // ExportBtn,
    // AdvancedExportBtn,
    DeleteBtn,
  },
};
</script>
