<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <!-- The ref newForm is needed to reset the form -->
      <v-form ref="newForm" v-model="valid" lazy-validation>
        <v-row>
          <!-- The form fields are dynamically created,
          based on the object received from the parent component -->
          <v-col
            v-for="(field, index) in newItem"
            :key="index"
            class="d-flex"
            cols="12"
            :lg="lg"
            :md="md"
            sm="6"
            xs="12"
          >
            <v-text-field
              v-if="!field.options && !field.date"
              v-model="field.value"
              :label="field.text"
              :prepend-icon="field.icon"
              required
              outlined
              dense
            ></v-text-field>
            <v-menu
              v-if="field.date"
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="field.value"
                  :label="field.text"
                  :prepend-icon="field.icon"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  outlined
                  required
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="field.value"
                :max="new Date().toISOString().substr(0, 10)"
                min="1950-01-01"
              ></v-date-picker>
            </v-menu>
            <v-select
              v-if="field.options"
              v-model="field.value"
              :items="field.options"
              menu-props="auto"
              :label="field.text"
              :prepend-icon="field.icon"
              clearable
              outlined
              dense
              required
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <!-- Triggers 'update' event and resets the form
    TODO: Añadir validación. Si no es correcto, no debe enviar nada;
    o mejor, no debe ni aparecer el botón -->
    <v-card-actions class="d-flex justify-end pb-4 pr-4 mt-0 pt-0">
      <v-btn
        color="primary"
        @click="
          $emit('update', newItem);
          $refs.newForm.reset();
        "
      >
        <v-icon left>mdi-floppy</v-icon>Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    // fieldRules: [(v) => !!v || "Obligatorio"],
  }),
  props: {
    title: String,
    newItem: Object,
  },
  computed: {
    /* Sets the number of columns on large breakpoints,
    according to the number of fields
    (i.e., Object.keys(this.newItem).length) */
    lg() {
      if (Object.keys(this.newItem).length === 6) {
        return 2;
      }
      if (Object.keys(this.newItem).length === 4) {
        return 3;
      }
      if (Object.keys(this.newItem).length === 10) {
        return 6;
      } else {
        return "auto";
      }
    },
    /* Sets the number of columns on medium breakpoints,
    according to the number of fields
    (i.e., Object.keys(this.newItem).length) */
    md() {
      if (Object.keys(this.newItem).length === 6) {
        return 4;
      }
      if (Object.keys(this.newItem).length === 4) {
        return 6;
      }
      if (Object.keys(this.newItem).length === 10) {
        return 6;
      } else {
        return "auto";
      }
    },
  },
};
</script>
