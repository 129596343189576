<template>
  <div>
    <!-- Removed ':item-key="headers[0].value"' to avoid issues in developemnt,
    as fake data doesn't use unique ID -->
    <v-data-table
      :headers="headers"
      :items="records"
      :search="search"
      show-group-by
      v-model="selected"
      show-select
      :single-select="singleSelect"
      :item-key="itemKey"
    >
      <!-- Creates dynamically the data-table columns, based on the headers array.
      Doesn't create a <v-edit-dialog> for partial_co2.
      Notice that exItem[exField] will work, but exItem[exObject.exField] won't,
      so we need to massage data from the REST API first. -->
      <template
        v-for="(header, index) in headers"
        v-slot:[`item.${header.value}`]="{ item }"
      >
        <v-edit-dialog
          :key="index"
          :return-value.sync="item[header.value]"
          @save="save(item, header.value)"
          @cancel="cancel"
          @open="open"
          @close="close"
        >
          {{ item[header.value] }}
          <template v-slot:input>
            <v-text-field
              v-model="item[header.value]"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <!-- The partial_co2 value is displayed inside an orange or red chip
      if it deviates from the expected values.
      Otherwise, the value is displayed inside a green chip -->
      <!-- <template v-slot:item.partial_co2="{ item }"> -->
      <!-- Creates a tooltip only if the chip is red -->
      <!-- <v-tooltip v-if="item.partial_co2 > outliers[0]" top>
          <template #activator="{ on }">
            <v-chip v-on="on" :color="getColor(item.partial_co2)" dark>
              {{ item.partial_co2 }}
            </v-chip>
          </template>
          <span>Valor atípico. Se recomienda revisión</span>
        </v-tooltip>

        <v-chip :color="getColor(item.partial_co2)" dark v-else>
          {{ item.partial_co2 }}
        </v-chip>
      </template> -->
    </v-data-table>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      singleSelect: false,
      //selected: this.$store.state.selected,
      options: {},
      max25chars: (v) => v.length <= 25 || "Input too long!",
      pagination: {},
      snack: false,
      snackColor: "",
      snackText: "",
      editedItem: {
        data: {},
        id: 0,
      },
    };
  },

  props: {
    headers: Array,
    records: Array,
    search: String,
    /* Array with 2 outliers. Example: [100, 80].
    outliers[0] is the extreme outlier
    (values greater than outliers[0] will be displayed in red),
    and outliers[1] is the mild outlier
    (values between outliers[0] and outliers[1] will be displayed in orange) */
    outliers: Array,
    showDeleteBtn: Boolean,
    itemKey: String,
  },

  watch: {
    // Shows delete button when there are selected items
    selected() {
      this.$emit("selected", this.selected);
      if (this.selected.length > 0) {
        this.showDeleteBtn = true;
        this.$emit("itemsSelected", this.showDeleteBtn);
      } else {
        this.showDeleteBtn = false;
        this.$emit("itemsSelected", this.showDeleteBtn);
      }
    },
  },

  computed: {
    selected: {
      get() {
        return this.$store.state.selected;
      },
      set(value) {
        this.$store.commit("updateSelected", value);
      },
    },
  },

  methods: {
    save(item, field) {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
      const editedItem = {};
      editedItem[field] = item[field];
      this.$emit("edited", { editedItem: editedItem, id: item.id });
    },

    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },

    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },

    close() {
      console.log("Dialog closed");
    },

    /* Calculates the color of the partial_co2 chip
    based on the outliers array received from the parent component */
    // getColor(partial_co2) {
    //   if (partial_co2 > this.outliers[0]) return "red";
    //   else if (partial_co2 > this.outliers[1]) return "orange";
    //   else return "green";
    // },
  },
};
</script>
