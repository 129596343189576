<template>
  <!-- Listens to the 'save-data' event and adds the new object
  to the vehicles stored in Vuex -->
  <Container
    :formTitle="formTitle"
    :tableTitle="tableTitle"
    :newItem="vehicle"
    :search="search"
    :headers="headers"
    :records="vehicles"
    :outliers="outliers"
    @save-data="createVehicle($event)"
    @edited="updateVehicle($event)"
    @deleted="deleteVehicles($event)"
    @imported="importVehicles($event)"
    :currentYear="currentYear"
    :itemKey="itemKey"
    :showYearSelector="false"
  />
</template>

<script>
import Container from "../inventory/common/Container.vue";
import { mapActions } from "vuex";
import { mapState } from "vuex";

export default {
  data() {
    return {
      formTitle: "Nuevo Vehículo",
      tableTitle: "Vehículos",
      search: "",
      outliers: [2500, 2100],
      itemKey: "id",
      currentYear: "2021",
    };
  },
  computed: {
    // Returns data-table data array from vehicles stored in Vuex
    ...mapState("vehicles", ["vehicles", "model"]),

    /* Creates and returns the data-table headers array
    from stationaryElectricityVehicleModel.headers stored in Vuex */
    headers() {
      return this.model.map((item) => {
        return item["headers"];
      });
    },

    /* Creates and returns a custom object
    to dynamically render the Form component fields,
    based on specific properties
    from stationaryElectricityVehicleModel stored in Vuex */
    vehicle() {
      const obj1 = {}; // The returned object
      /* Creates a nested object in obj1,
      for each object found in stationaryElectricityVehicleModel stored in Vuex
      which form property evaluates to true.
      Example:
      {
        invoiceNumber: {
          value: "",
          text: "Número",
          icon: "mdi-file-document"
        },
        ...
      } */
      this.model.forEach((x) => {
        if (x.form) {
          let obj2 = {}; // The nested object
          Object.assign(obj2, { value: x.value });
          Object.assign(obj2, { text: x.headers.text });
          Object.assign(obj2, { icon: x.icon });
          if (x.options) {
            Object.assign(obj2, { options: x.options });
          }
          if (x.date) {
            Object.assign(obj2, { date: true });
          }
          //
          Object.assign(obj1, { [x.name]: obj2 });
        }
      });
      return obj1;
    },
  },

  components: {
    Container,
  },

  methods: {
    ...mapActions({
      readVehicles: "vehicles/readVehicles",
      createVehicle: "vehicles/createVehicle",
      updateVehicle: "vehicles/updateVehicle",
      deleteVehicles: "vehicles/deleteVehicles",
      importVehicles: "vehicles/importVehicles",
    }),

    // loadVehicles(year) {
    //   if (year != this.currentYear) {
    //     this.currentYear = year;
    //   }
    //   this.$store.dispatch("vehicles/readVehicles", year);
    // },
  },

  created() {
    this.readVehicles;
  },
};
</script>
