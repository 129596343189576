<template>
  <v-text-field
    v-model="search"
    @keyup="$emit('update', search)"
    append-icon="mdi-magnify"
    label="Buscar"
    single-line
    hide-details
  ></v-text-field>
</template>

<script>
export default {
  props: {
    search: String,
  },
};
</script>
