<template>
  <v-btn
    color="red accent-3"
    dark
    fab
    bottom
    right
    fixed
    @click="$emit('deleted', itemsToDelete)"
    ><v-icon dark>mdi-delete</v-icon></v-btn
  >
</template>

<script>
export default {
  props: {
    itemsToDelete: Array,
  },
};
//
</script>
