<template>
  <div>
    <!-- Switches between the two ways of inventoring
    mobile combustion emissions (invoices/routes) -->
    <v-tabs centered background-color="transparent">
      <v-tab @click="tab = 'invoices'">Facturas</v-tab>
      <v-tab @click="tab = 'routes'">Viajes</v-tab>
    </v-tabs>
    <MobileCombustionInvoice v-if="tab === 'invoices'" />
    <Route v-if="tab === 'routes'" />
  </div>
</template>

<script>
import MobileCombustionInvoice from "./MobileCombustionInvoice.vue";
import Route from "../Route.vue";

export default {
  data: () => ({
    tab: "invoices",
  }),
  components: {
    MobileCombustionInvoice,
    Route,
  },
};
</script>
