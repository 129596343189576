<template>
  <v-col cols="auto">
    <v-select
      :items="years"
      v-model="selectedYear"
      dense
      solo
      hide-details
    ></v-select>
  </v-col>
</template>

<script>
export default {
  props: {
    currentYear: String,
  },

  computed: {
    years() {
      return Array.from(new Array(16), (x, i) => i + 2015).map(String);
    },
    selectedYear: {
      get() {
        return this.currentYear;
      },
      set(value) {
        this.$emit("year-selected", value);
      },
    },
  },
};
</script>
