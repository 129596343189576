import axios from "../../axiosSegaraCo2";
import store from "@/store";

const state = {
  facilities: [],
  model: [
    {
      name: "name",
      value: "",
      icon: "mdi-office-building",
      form: true,
      headers: {
        text: "Nombre",
        align: "start",
        sortable: true,
        value: "name",
        groupable: false,
      },
    },
    {
      name: "organisation_name",
      value: "",
      icon: "mdi-family-tree",
      form: true,
      headers: {
        text: "Organización",
        value: "organisation_name",
      },
    },
    {
      name: "latitude",
      value: "",
      icon: "mdi-latitude",
      form: true,
      headers: {
        text: "Latitud",
        value: "latitude",
      },
    },
    {
      name: "longitude",
      value: "",
      icon: "mdi-longitude",
      form: true,
      headers: {
        text: "Longitud",
        value: "longitude",
      },
    },
    {
      name: "address",
      value: "",
      icon: "mdi-map-marker",
      form: true,
      headers: {
        text: "Dirección",
        value: "address",
      },
    },
    {
      name: "zip_code",
      value: "",
      icon: "mdi-numeric",
      form: true,
      headers: {
        text: "Código Postal",
        value: "zip_code",
      },
    },
    {
      name: "city",
      value: "",
      icon: "mdi-home-city",
      form: true,
      headers: {
        text: "Municipio",
        value: "city",
      },
    },
    {
      name: "country",
      value: "",
      options: [
        "Afganistán",
        "Albania",
        "Alemania",
        "Andorra",
        "Angola",
        "Antigua y Barbuda",
        "Arabia Saudita",
        "Argelia",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaiyán",
        "Bahamas",
        "Bangladés",
        "Barbados",
        "Baréin",
        "Bélgica",
        "Belice",
        "Benín",
        "Bielorrusia",
        "Birmania",
        "Bolivia",
        "Bosnia y Herzegovina",
        "Botsuana",
        "Brasil",
        "Brunéi",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Bután",
        "Cabo Verde",
        "Camboya",
        "Camerún",
        "Canadá",
        "Catar",
        "Chad",
        "Chile",
        "China",
        "Chipre",
        "Ciudad del Vaticano",
        "Colombia",
        "Comoras",
        "Corea del Norte",
        "Corea del Sur",
        "Costa de Marfil",
        "Costa Rica",
        "Croacia",
        "Cuba",
        "Dinamarca",
        "Dominica",
        "Ecuador",
        "Egipto",
        "El Salvador",
        "Emiratos Árabes Unidos",
        "Eritrea",
        "Eslovaquia",
        "Eslovenia",
        "España",
        "Estados Unidos",
        "Estonia",
        "Etiopía",
        "Filipinas",
        "Finlandia",
        "Fiyi",
        "Francia",
        "Gabón",
        "Gambia",
        "Georgia",
        "Ghana",
        "Granada",
        "Grecia",
        "Guatemala",
        "Guyana",
        "Guinea",
        "Guinea ecuatorial",
        "Guinea-Bisáu",
        "Haití",
        "Honduras",
        "Hungría",
        "India",
        "Indonesia",
        "Irak",
        "Irán",
        "Irlanda",
        "Islandia",
        "Islas Marshall",
        "Islas Salomón",
        "Israel",
        "Italia",
        "Jamaica",
        "Japón",
        "Jordania",
        "Kazajistán",
        "Kenia",
        "Kirguistán",
        "Kiribati",
        "Kuwait",
        "Laos",
        "Lesoto",
        "Letonia",
        "Líbano",
        "Liberia",
        "Libia",
        "Liechtenstein",
        "Lituania",
        "Luxemburgo",
        "Madagascar",
        "Malasia",
        "Malaui",
        "Maldivas",
        "Malí",
        "Malta",
        "Marruecos",
        "Mauricio",
        "Mauritania",
        "México",
        "Micronesia",
        "Moldavia",
        "Mónaco",
        "Mongolia",
        "Montenegro",
        "Mozambique",
        "Namibia",
        "Nauru",
        "Nepal",
        "Nicaragua",
        "Níger",
        "Nigeria",
        "Noruega",
        "Nueva Zelanda",
        "Omán",
        "Países Bajos",
        "Pakistán",
        "Palaos",
        "Palestina",
        "Panamá",
        "Papúa Nueva Guinea",
        "Paraguay",
        "Perú",
        "Polonia",
        "Portugal",
        "Reino Unido",
        "República Centroafricana",
        "República Checa",
        "República de Macedonia",
        "República del Congo",
        "República Democrática del Congo",
        "República Dominicana",
        "República Sudafricana",
        "Ruanda",
        "Rumanía",
        "Rusia",
        "Samoa",
        "San Cristóbal y Nieves",
        "San Marino",
        "San Vicente y las Granadinas",
        "Santa Lucía",
        "Santo Tomé y Príncipe",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leona",
        "Singapur",
        "Siria",
        "Somalia",
        "Sri Lanka",
        "Suazilandia",
        "Sudán",
        "Sudán del Sur",
        "Suecia",
        "Suiza",
        "Surinam",
        "Tailandia",
        "Tanzania",
        "Tayikistán",
        "Timor Oriental",
        "Togo",
        "Tonga",
        "Trinidad y Tobago",
        "Túnez",
        "Turkmenistán",
        "Turquía",
        "Tuvalu",
        "Ucrania",
        "Uganda",
        "Uruguay",
        "Uzbekistán",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Yibuti",
        "Zambia",
        "Zimbabue",
      ],
      icon: "mdi-flag",
      form: true,
      headers: {
        text: "País",
        value: "country",
      },
    },
    {
      name: "outliers_combustion",
      value: "",
      icon: "mdi-radiator",
      form: true,
      headers: {
        text: "Valores atípicos combustión",
        value: "outliers_combustion",
      },
    },
    {
      name: "outliers_electricity",
      value: "",
      icon: "mdi-flash",
      form: true,
      headers: {
        text: "Valores atípicos electricidad",
        value: "outliers_electricity",
      },
    },
  ],
};

const mutations = {
  saveFacilities: (state, payload) => {
    state.facilities = payload;
  },
};

const actions = {
  readFacilities: ({ commit }) => {
    axios.get("facilities/search/all/?skip=0&limit=100").then((response) => {
      commit("saveFacilities", response.data);
    });
  },

  createFacility: ({ dispatch }, payload) => {
    axios
      .post("facilities/create/", payload)
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readFacilities"));
  },

  importFacilities: ({ dispatch }, payload) => {
    axios
      .post("facilities/import/all/", payload)
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readFacilities"));
  },

  updateFacility: ({ dispatch }, payload) => {
    axios
      .put("facilities/edit/id/", payload.editedItem, {
        params: {
          facility_id: payload.id,
        },
      })
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readFacilities"));
  },

  deleteFacilities: ({ dispatch }, payload) => {
    payload.forEach((facility) => {
      axios
        .delete("facilities/delete/id/", {
          params: {
            facility_id: facility.id,
          },
        })
        .then(() => (store.state.selected = []))
        // We want to update the data table after removing data from the database
        .then(() => dispatch("readFacilities"));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
