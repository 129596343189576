import axios from "../../axiosSegaraCo2";
import store from "@/store";
import gas from "../../data/gas.json";

const state = {
  fluorocarbons: [],
  model: [
    {
      name: "annual_refilling",
      value: "",
      icon: "mdi-water",
      form: true,
      headers: {
        text: "Recarga anual",
        align: "start",
        sortable: true,
        value: "annual_refilling",
        groupable: false,
      },
    },
    {
      name: "year",
      value: "",
      icon: "mdi-calendar",
      form: true,
      headers: {
        text: "Año",
        value: "year",
      },
    },
    {
      name: "gas_name",
      value: "",
      icon: "mdi-molecule",
      form: true,
      options: gas.map((item) => {
        return item["name"];
      }),
      headers: {
        text: "Gas",
        value: "gas",
      },
    },
    {
      name: "equipment_nickname",
      value: "",
      icon: "mdi-air-conditioner",
      form: true,
      options: ["Toshiba A/C Sala de reuniones", "Mitsubishi A/C Despacho"],
      headers: {
        text: "Equipo",
        value: "equipment_nickname",
      },
    },

    {
      name: "partialCo2",
      form: false,
      headers: {
        text: "Parcial CO2",
        value: "partial_co2",
      },
    },
    {
      name: "notes",
      form: false,
      headers: {
        text: "Notas",
        value: "notes",
      },
    },
  ],
};

const mutations = {
  massageData: (state, api_fluorocarbons) => {
    api_fluorocarbons.forEach((fluorocarbon) => {
      let gas = fluorocarbon["gas"];
      fluorocarbon["gas"] = gas["name"];
    });
    state.fluorocarbons = api_fluorocarbons;
  },
};

const actions = {
  readFluorocarbons: ({ commit }, payload) => {
    axios.get(`fluorocarbons/search/year/?year=${payload}`).then((response) => {
      commit("massageData", response.data);
    });
  },

  createFluorocarbon: ({ dispatch }, payload) => {
    axios
      .post("fluorocarbons/create/", payload.data)
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readFluorocarbons", payload.year));
  },

  importFluorocarbons: ({ dispatch }, payload) => {
    axios
      .post("fluorocarbons/import/all/", payload.data)
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readFluorocarbons", payload.year));
  },

  updateFluorocarbon: ({ dispatch }, payload) => {
    axios
      .put("fluorocarbons/edit/id/", payload.data.editedItem, {
        params: {
          fluorocarbon_id: payload.data.id,
        },
      })
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readFluorocarbons", payload.year));
  },

  deleteFluorocarbons: ({ dispatch }, payload) => {
    payload.data.forEach((fluorocarbon) => {
      axios
        .delete("fluorocarbons/delete/id/", {
          params: {
            fluorocarbon_id: fluorocarbon.id,
          },
        })
        .then(() => (store.state.selected = []))
        // We want to update the data table after removing data from the database
        .then(() => dispatch("readFluorocarbons", payload.year));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
