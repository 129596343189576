<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto my-12" max-width="800">
          <v-card-title>
            {{ this.title }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              ref="organisation"
              v-model="editedItem['name']"
              label="Organización"
              prepend-icon="mdi-family-tree"
              :disabled="!this.editMode"
              required
              outlined
            >
            </v-text-field
            ><v-text-field
              ref="year"
              v-model="editedItem['base_year']"
              label="Año base"
              prepend-icon="mdi-calendar"
              type="number"
              :disabled="!this.editMode"
              outlined
            >
            </v-text-field>
            <v-select
              ref="sector"
              v-model="editedItem['sector_name']"
              label="Sector"
              :items="this.sectors"
              prepend-icon="mdi-briefcase"
              :disabled="!this.editMode"
              required
              outlined
            >
            </v-select
          ></v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="enableEditMode"
              >Editar<v-icon right dark>mdi-pencil</v-icon></v-btn
            >
            <v-col class="text-right">
              <v-btn
                color="red accent-3"
                dark
                v-if="editMode"
                @click="disableEditMode"
                >Descartar</v-btn
              >
            </v-col>
            <v-btn
              color="success"
              dark
              v-if="editMode"
              @click="editOrganisation"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    title: "Organización",
    editMode: false,
    id: 0,
    editedItem: {
      name: "",
      sector_name: "",
      base_year: 0,
    },
  }),

  computed: {
    ...mapState("organisations", ["organisations"]),
    ...mapState("sectors", ["sectors"]),
  },

  methods: {
    enableEditMode() {
      this.editMode = true;
    },
    disableEditMode() {
      this.editMode = false;
      this.setValues();
    },
    setValues() {
      this.editedItem["name"] = this.organisations[0].name;
      this.editedItem["base_year"] = this.organisations[0].base_year;
      this.editedItem["sector_name"] = this.organisations[0].sector_name;
      this.id = this.organisations[0].id;
    },
    editOrganisation() {
      this.$store.dispatch("organisations/updateOrganisation", {
        editedItem: this.editedItem,
        id: this.id,
      });
      this.editMode = false;
    },
  },

  created() {
    this.$store.dispatch("organisations/readOrganisations");
    this.setValues();
    this.$store.dispatch("sectors/readSectors");
  },
};
</script>
