<template>
  <v-app>
    <v-main class="grey lighten-3">
      <v-container fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg3 x3>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>SegaraCO2</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <form ref="form" @submit.prevent="submit">
                  <v-text-field
                    v-model="form.username"
                    name="username"
                    label="Usuario"
                    type="text"
                    prepend-icon="mdi-account"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="form.password"
                    name="password"
                    label="Contraseña"
                    type="password"
                    prepend-icon="mdi-lock"
                    required
                  ></v-text-field>
                  <v-btn type="submit" class="mt-4" color="primary"
                    >Iniciar sesión</v-btn
                  >
                </form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showError: false,
    };
  },
  methods: {
    ...mapActions({ login: "auth/login" }),
    async submit() {
      const User = new FormData();
      User.append("username", this.form.username);
      User.append("password", this.form.password);
      try {
        await this.login(User);
        this.$router.push("/");
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>
