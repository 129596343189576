import axios from "../../axiosSegaraCo2";
import store from "@/store";
import vehicles from "../../data/vehicles.json";

const state = {
  routes: [],
  model: [
    {
      name: "distance",
      value: "",
      icon: "mdi-map-marker-distance",
      form: true,
      headers: {
        text: "Distancia",
        align: "start",
        sortable: true,
        value: "distance",
        groupable: false,
      },
    },
    {
      name: "route_date",
      value: "",
      icon: "mdi-calendar",
      date: true,
      form: true,
      headers: {
        text: "Fecha",
        value: "route_date",
      },
    },
    {
      name: "vehicle_license_plate",
      value: "",
      icon: "mdi-car",
      form: true,
      // Return only ICV vehicles
      options: vehicles
        .filter((item) => {
          if (item.emissionFactor > 0) {
            return item;
          }
        })
        .map((item) => {
          return item["name"];
        }),
      headers: {
        text: "Vehículo",
        value: "vehicle_license_plate",
      },
    },
    {
      name: "driving_name",
      value: "",
      icon: "mdi-gauge",
      form: true,
      options: ["City", "Road", "Mixed"],
      headers: {
        text: "Conducción",
        value: "driving_name",
      },
    },

    {
      name: "partialCo2",
      form: false,
      headers: {
        text: "Parcial CO2",
        value: "partial_co2",
      },
    },
    {
      name: "notes",
      form: false,
      headers: {
        text: "Notas",
        value: "notes",
      },
    },
  ],
};

const mutations = {
  addRoute: (state, api_routes) => {
    state.routes = api_routes;
  },
};

const actions = {
  readRoutes: ({ commit }, payload) => {
    axios
      .get(`routes/search/dates/?date1=${payload}-01-01&date2=${payload}-12-31`)
      .then((response) => {
        commit("addRoute", response.data);
      });
  },

  createRoute: ({ dispatch }, payload) => {
    axios
      .post("routes/create/", payload.data)
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readRoutes", payload.year));
  },

  importRoutes: ({ dispatch }, payload) => {
    axios
      .post("routes/import/all/", payload.data)
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readRoutes", payload.year));
  },

  updateRoute: ({ dispatch }, payload) => {
    axios
      .put("routes/edit/id/", payload.data.editedItem, {
        params: {
          route_id: payload.data.id,
        },
      })
      // We want to update the data table after commiting new data into the database
      .then(() => dispatch("readRoutes", payload.year));
  },

  deleteRoutes: ({ dispatch }, payload) => {
    payload.data.forEach((route) => {
      axios
        .delete("routes/delete/id/", {
          params: {
            route_id: route.id,
          },
        })
        .then(() => (store.state.selected = []))
        // We want to update the data table after removing data from the database
        .then(() => dispatch("readRoutes", payload.year));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
