<template>
  <!-- Listens to the 'save-data' event and adds the new object
  to the routes stored in Vuex -->
  <Container
    :formTitle="formTitle"
    :tableTitle="tableTitle"
    :newItem="route"
    :search="search"
    :headers="headers"
    :records="routes"
    :outliers="outliers"
    @save-data="createRoute({ data: $event, year: currentYear })"
    @edited="updateRoute({ data: $event, year: currentYear })"
    @deleted="deleteRoutes({ data: $event, year: currentYear })"
    @imported="importRoutes({ data: $event, year: currentYear })"
    @year-selected="loadRoutes($event)"
    :currentYear="currentYear"
    :itemKey="itemKey"
    :showYearSelector="true"
  />
</template>

<script>
import Container from "./common/Container.vue";
import { mapActions } from "vuex";
import { mapState } from "vuex";

export default {
  data() {
    return {
      formTitle: "Nuevo Trayecto",
      tableTitle: "Historial de Trayectos",
      search: "",
      outliers: [10, 9],
      itemKey: "id",
      currentYear: "2021",
    };
  },

  computed: {
    // Returns data-table data array from routes stored in Vuex
    ...mapState("combustionRoutes", ["routes", "model"]),

    /* Creates and returns the data-table headers array
    from routeModel.headers stored in Vuex */
    headers() {
      return this.model.map((item) => {
        return item["headers"];
      });
    },
    /* Creates and returns a custom object
    to dynamically render the Form component fields,
    based on specific properties
    from routeModel stored in Vuex */

    route() {
      const obj1 = {}; // The returned object
      /* Creates a nested object in obj1,
      for each object found in routeModel stored in Vuex
      which form property evaluates to true.
      Example:
      {
        distance: {
          value: "",
          text: "Distancia",
          icon: "mdi-map-marker-distance"
        },
        ...
      } */
      this.model.forEach((x) => {
        if (x.form) {
          let obj2 = {}; // The nested object
          Object.assign(obj2, { value: x.value });
          Object.assign(obj2, { text: x.headers.text });
          Object.assign(obj2, { icon: x.icon });
          if (x.options) {
            Object.assign(obj2, { options: x.options });
          }
          if (x.date) {
            Object.assign(obj2, { date: true });
          }
          //
          Object.assign(obj1, { [x.name]: obj2 });
        }
      });
      return obj1;
    },
  },

  components: {
    Container,
  },

  methods: {
    ...mapActions({
      readRoutes: "combustionRoutes/readRoutes",
      createRoute: "combustionRoutes/createRoute",
      updateRoute: "combustionRoutes/updateRoute",
      deleteRoutes: "combustionRoutes/deleteRoutes",
      importRoutes: "combustionRoutes/importRoutes",
    }),

    loadRoutes(year) {
      if (year != this.currentYear) {
        this.currentYear = year;
      }
      this.$store.dispatch("combustionRoutes/readRoutes", year);
    },
  },

  created() {
    this.loadRoutes(this.currentYear);
  },
};
</script>
